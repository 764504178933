
const 	pageSwapperTriggers = document.querySelectorAll('.js-page-swapper'),
		pageSwapperPages = document.querySelectorAll('.js-page-swapper-target');

[...pageSwapperTriggers].forEach( function ( pageSwapperTrigger ) {

	pageSwapperTrigger.addEventListener('click', function ( e ) {

		e.preventDefault();

		const 	targetPageId = pageSwapperTrigger.dataset.pageId,
				targetPageDomElem = document.getElementById(targetPageId);

		if (targetPageId === "question-one") {
			const name = document.getElementById('name-input');
			const email = document.getElementById('email-input');

			if (!name.checkValidity() || !email.checkValidity()) {
				alert("Please enter all fields.");
				return false;
			}
		}

		if ( targetPageDomElem ) {

			hideAllPages();

			targetPageDomElem.classList.add('active');

		} else {

			console.error('The page with the ID you are calling does not exist.');

		}

	} )

} )

function hideAllPages() {

	[...pageSwapperPages].forEach( function ( pageSwapperPage ) {

		pageSwapperPage.classList.remove('active');

	} )

}