
const answers = document.querySelectorAll('.question-select__question');
const showResultButtons = document.querySelectorAll('.js-show-result');
const results = document.querySelectorAll('.result');
const resetButtons = document.querySelectorAll('[data-page-id="landing"]');
const nameInput = document.getElementById('name-input');
const emailInput = document.getElementById('email-input');
const gdprInput = document.getElementById('gdpr-input');
let result = "";

let sectionATotal = 0, sectionBTotal = 0, sectionCTotal = 0, currentQuestionNumber = 1;

[...answers].forEach(function (answer) {

    answer.addEventListener('click', function (e) {

        e.preventDefault();

        const index = answer.dataset.index;
        const result = answer.dataset.result;

        answer.parentNode.classList.toggle("clicked");

        if (answer.parentNode.classList.contains("clicked")) {
            if (result === "A") {
                sectionATotal++;
            }
            else if (result === "B") {
                sectionBTotal++;
            }
            else if (result === "C") {
                sectionCTotal++;
            }
        }
        else {
            if (result === "A") {
                sectionATotal--;
            }
            else if (result === "B") {
                sectionBTotal--;
            }
            else if (result === "C") {
                sectionCTotal--;
            }
        }

        //console.log(sectionATotal, sectionBTotal, sectionCTotal);

    } )

} ),

[...showResultButtons].forEach(function (button) {

    button.addEventListener('click', function (e) {

        e.preventDefault();

        const answerData = getAnswerData();

        //console.log(sectionATotal, sectionBTotal, sectionCTotal);

        let resultWrap = document.querySelector(".result[data-result='A']");
        result = "Seeker";

        if (sectionBTotal > sectionATotal && sectionBTotal > sectionCTotal) {
            resultWrap = document.querySelector(".result[data-result='B']");
            result = "Striver";
        }
        else if (sectionCTotal > sectionATotal && sectionCTotal > sectionATotal) {
            resultWrap = document.querySelector(".result[data-result='C']");
            result = "Perfectionist";
        }

        var timestamp = Date.now();
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        var date = day + "-" + month + "-" + year + " " + hours + ":" + minutes + ":" + seconds;

        const submissionData = {
            name: nameInput.value,
            email: emailInput.value,
            gdpr: gdprInput.checked ? "Opted in" : "Opted out",
            summary: sectionATotal + "," + sectionBTotal + "," + sectionCTotal,
            result: result,
            id: Math.round(timestamp / 1000),
            date: date,
            answers: answerData
        }
        addSubmission(submissionData);

        resultWrap.classList.add("is-active");

    } )

} ),

[...resetButtons].forEach(function (button) {

    button.addEventListener('click', function (e) {

        e.preventDefault();
        console.log("reset");

        sectionATotal = 0, sectionBTotal = 0, sectionCTotal = 0, currentQuestionNumber = 1;

        nameInput.value = '';
        emailInput.value = '';
        gdprInput.checked = false;
        result = "";

        [...answers].forEach(function (answer) {
            answer.parentNode.classList.remove("clicked");
        } ),
        [...results].forEach(function (result) {
            result.classList.remove("is-active");
        } )

    } )

} )

function getAnswerData() {
    const questions = document.querySelectorAll(".question-select__questions");
    let answers = "";

    [...questions].forEach(function (question) {
        const qNum = question.dataset.q;
        const answersSelector = question.querySelectorAll(".question-select__question-wrap.clicked");

        let answerData = "";

        [...answersSelector].forEach(function (answer) {
            const aI = answer.querySelector(".question-select__question");
            const aNum = aI.dataset.index.toString();
            answerData = answerData + aNum + " ";
        } );

        answers = answers + "q" + qNum +":" + answerData + " ";

    } )

    return answers;
}

function checkOnlineStatus(e) {
    const status = navigator.onLine ? 'online' : 'offline';
    return status;
}

function addSubmission(submission) {
    const currentSubmissions = JSON.parse(localStorage.getItem('submissions')) || [];
    const submissions = [...currentSubmissions, submission];

    window.localStorage.setItem('submissions', JSON.stringify(submissions));

    if (checkOnlineStatus() === 'online') {
        console.log("online");
        pushStateToDatabase(submissions);
    }
}

function pushStateToDatabase(submissions) {
    console.log('push to db');
    //debugger

    submissions.forEach(submission => {
        firebase.database().ref('submissions/' + submission.id + "_" + submission.name).set({
            answers: submission.answers,
            date: submission.date,
            email: submission.email,
            gdpr: submission.gdpr,
            name: submission.name,
            result: submission.result,
            summary: submission.summary
        }).then(() => {
            //
        }).catch(err => {
            console.log(err);
        });
    });
}

window.addEventListener('online', () => {
    if (checkOnlineStatus() === 'online') {
        const currentSubmissions = JSON.parse(localStorage.getItem('submissions')) || [];
        console.log("push");
        pushStateToDatabase(currentSubmissions);
    }
});
