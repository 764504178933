
const	pageWrappers = document.querySelectorAll('.js-page-wrapper');

function definePageWrapperHeights () {

	let viewportHeight = window.innerHeight;

	[...pageWrappers].forEach( function ( pageWrapper ) {

		pageWrapper.style.height = viewportHeight + 'px';

	} )

}

definePageWrapperHeights();

window.addEventListener( 'resize', function () {

	definePageWrapperHeights();

} );


